.Container {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
}
.innerContainer {
  margin-top: auto;
  margin-bottom: auto;
}

.faceIcon {
  font-size: 400px;
}

.text {
  max-width: 400px;
}